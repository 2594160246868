import React from 'react'
import Layout from '../../components/layout/layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarSeThree from "../../components/layout/sidemenu/sidemenu_se_three"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import ContentHeader from '../../components/page-sections/content-header'
import FocusQuestion from '../../components/page-sections/focus-question'
import Introduction from '../../components/page-sections/introduction'
import ButtonTop from '../../components/buttons/button-top'
import ButtonNext from '../../components/buttons/button-next'

import rowImage from '../../queries/images/row-image'

import unit3Icon from '../../images/icons/unit_03_icon.svg'
import TVWeather36 from '../../images/student-pages/unit-3/36_tv_weather.jpg'
import homeEnergyCategories37 from '../../images/student-pages/unit-3/37_home_energy_categories.gif'
import appliances38 from '../../images/student-pages/unit-3/38_appliances.jpg'
import biking39 from '../../images/student-pages/unit-3/39_biking.jpg'


const ItStartsAtHome34 = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - 3.4 It Starts at Home" />
    <Container className="mb-5" fluid>
      <Row>
        <Col md="auto">
          <SideBarSeThree location={props.location.pathname} />
        </Col>
        <Col>
          <CCBreadcrumb
            pathname={props.location.pathname}
            title={'3.4 It Starts at Home'}
            replace={props.replace}
          />
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
          <Card>
            <Card.Body>

              <ContentHeader
                icon={ unit3Icon }
                iconAlt="Unit 3 icon"
                studentPageHeader="Unit 3: Carbon in the Future &amp; You"
                unitNumber="3.4"
                studentPageHeaderSub="It Starts at Home"
                sectionA={ <>Conserving Energy at Home</> }
                sectionALink="/unit-3/3.4-it-starts-at-home/#conserving-energy-at-home"
                sectionB={ <>Energy Efficiency</> }
                sectionBLink="/unit-3/3.4-it-starts-at-home/#energy-efficiency"
                sectionC={ <>Carbon Calculator and Your First Job</> }
                sectionCLink="/unit-3/3.4-it-starts-at-home/#carbon-calculator-and-your-first-job"
                sectionD={ <>Reflect and Connect</> }
                sectionDLink="/unit-3/3.4-it-starts-at-home/#reflect-and-connect"
              />

              <FocusQuestion
                focusQuestionBodyText={ <>How can I conserve energy and be more efficient with energy use?</> }
              />

              <Introduction
                paragraphOne={
                  <>
                    <Row className="d-md-none">
                      <Col>
                        <Img
                          className="rounded img-fluid mb-3"
                          fluid={props.data.image29.childImageSharp.fluid}
                          alt="Students in a classroom"
                        />
                      </Col>
                    </Row>
                    <Img
                      className="smallImageRight rounded mb-3 img-fluid d-none d-md-block ml-3"
                      fluid={props.data.image29.childImageSharp.fluid}
                      alt="Students in a classroom"
                    />

                    <p>In <em>Carbon Connections</em>, you have seen how the carbon cycle connects with Earth's climate. Humans are now an important part of that carbon and climate connection. Scientists have tested this link with many kinds of data and models.</p>
                  </>
                }

                paragraphTwo={
                  <>
                    <p>But how does this relate to you? You started to investigate this in Unit 2 with the energy-use monitor and devices at your school. Still, you might wonder whether you really can make a difference. Remember that it's not just you&mdash;it's you and many, many other students just like you. That's where you <em>all</em> can make a difference.</p>
                  </>
                }

                paragraphThree={
                  <>
                    <p><em>National Geographic</em> magazine had an article in 2010, "It Starts at Home." The article showed how conserving electrical energy can reduce the size of the human factor on climate. For you, home is also a good place to start. In this lesson you will learn:</p>
                    <ul>
                      <li>How to measure energy use by appliances in your home.</li>
                      <li>The difference between conserving energy and increased energy efficiency.</li>
                      <li>How to reduce your carbon footprint, as well as your monthly energy bill.</li>
                    </ul>
                  </>
                }
              />

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="conserving-energy-at-home" className="cardHeader">
                      <h2>
                        A. <span className="headerFontBlue">Conserving Energy at Home</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image30.childImageSharp.fluid}
                            alt="Light bulb"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="verticalImageRight rounded mb-3 ml-3 img-fluid d-none d-md-block"
                        fluid={props.data.image30.childImageSharp.fluid}
                        alt="Light bulb"
                      />

                      <p>
                        You may have heard of conserving energy. To <em>conserve</em> means to save, protect, or use sparingly. So, when you conserve energy, you are adjusting your habits or behavior so that you use less energy. An obvious example is turning off lights or the television when nobody is in the room.
                      </p>

                      <p>
                        Recycling is another good way to conserve energy. For example, does your community recycle aluminum or paper? It can be costly in terms of both energy and money to get new, raw materials from the earth. In contrast, less energy is used to get materials when they are recycled.
                      </p>

                      <ol>
                        <li>
                          Share your ideas about saving energy. If someone in your class took home the energy-use monitor, he or she may have data about the amount of energy used by some devices. (Remember that watts (W) tells the rate at which energy is being used, and <em>kilowatt-hours</em> (kWh) tells how much total energy was used.)
                          <ol type="a">
                            <li>Write an idea for one way that you could use a little less electrical energy at home or at school.</li>
                            <li>Do you recycle materials at home or school? If yes, list the kinds of things you recycle. If no, write two things that you could start recycling.</li>
                          </ol>
                        </li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="energy-efficiency" className="cardHeader">
                      <h2>
                        B. <span className="headerFontBlue">Energy Efficiency</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        Conserving energy and energy efficiency have the same overall goal&mdash;using less energy. But conservation and efficiency achieve the goal in different ways. You just considered some ways to save energy. In contrast, <strong>efficiency</strong> tells how something gets done without wasted time or effort. Using less is conservation. Efficiency is using less energy or effort to do the same thing.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image31.childImageSharp.fluid}
                            alt="Refrigerator"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="verticalImageRight rounded ml-3 mb-3 img-fluid d-none d-md-block"
                        fluid={props.data.image31.childImageSharp.fluid}
                        alt="Refrigerator"
                      />

                      <p>
                        Here's an example. Devices in your home have certain efficiencies. Storing milk in your refrigerator keeps the milk from spoiling. An <em>energy-efficient</em> refrigerator uses less electrical energy to keep milk at that low temperature.
                      </p>

                      <p>
                        Why does energy efficiency matter? Well, you've learned that electricity is generated in power plants. Most electrical energy is generated through the combustion of fossil fuels. As you saw in Lesson 2.4, fossil fuels are part of the carbon cycle. In Lesson 3.2, you also used a model to test how carbon is connected to climate: That is an important idea in <em>Carbon Connections</em>. Using less electrical energy means less impact on the climate and a smaller energy bill for your family.
                      </p>

                      <p>
                        You may wonder if conserving energy and energy efficiency have an impact. They do! The reason is that the whole system has savings that you don't see. Starting with what you save at home, the system has a multiplier of about three times more. You can see this with an example from your home
                      </p>

                      <p>
                        The average color television uses about 130 kWh per year. But what if your family watched eight percent less television? This would save 10.4 kWh per year. (You can see this in the table below.) However, you really save three times that much energy, or 31.5 kWh per year. How? Think about how much energy it takes to generate that 10.4 kWh per year for your television. The table shows that saving eight percent can result in larger savings and much less CO<sub>2</sub> emissions. The 10.4 kWh you save is about 13.9 pounds less CO<sub>2</sub> to the atmosphere. But for the entire system, it is a bigger savings: three times the amount, or 42.2 pounds, of CO<sub>2</sub> to the atmosphere.
                      </p>

                      <p className="lead">Home and System Savings per year</p>
                      <table className="table table-bordered table-hover table-responsive-md studentTable">
                        <thead>
                          <tr className="text-center">
                            <th className="table-active" scope="col"></th>
                            <th className="table-active" scope="col">Energy use by average color television (kWh)</th>
                            <th className="table-active" scope="col">Energy use by average television with 8% savings (kWh)</th>
                            <th className="table-active" scope="col">Total energy savings (kWh)</th>
                            <th className="table-active" scope="col">Total CO<sub>2</sub> savings as emissions (pounds CO<sub>2</sub>)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><strong>Home:</strong> Energy use by average color TV</td>
                            <td className="text-center">130</td>
                            <td className="text-center">119.6</td>
                            <td className="text-center">10.4</td>
                            <td className="text-center">13.9</td>
                          </tr>
                          <tr>
                            <td><strong>System:</strong> Energy needed to provide power for TV</td>
                            <td className="text-center">394</td>
                            <td className="text-center">362.4</td>
                            <td className="text-center">31.5</td>
                            <td className="text-center">42.2</td>
                          </tr>
                        </tbody>
                      </table>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image32.childImageSharp.fluid}
                            alt="Power grids"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight rounded ml-3 img-fluid d-none d-md-block"
                        fluid={props.data.image32.childImageSharp.fluid}
                        alt="Power grids"
                      />

                      <p>
                        Why does it take so much energy to generate electricity for your home? The reason is that about 66 percent of the energy is lost before arriving at your home. It is lost as heat at the power plant, as well as when the electrical energy is being transferred to your home. A car or truck engine is another example of a system that loses energy as heat. Just feel a hood of your car after a trip to the grocery store!
                      </p>

                      <p>
                        Answer the following questions about energy efficiency and saving energy in your home.
                      </p>

                      <ol start="2">
                        <li>Consider the computer or laptop you're using now. Electrical energy enters it so you can use it, but the device isn't 100 percent efficient. What is evidence that it's not 100 percent efficient?</li>
                        <li>Imagine a family member standing and looking into the refrigerator. The door is wide open. They scan to look for a savory snack. Leaving the door open uses extra energy. Does that mean that the efficiency of the refrigerator has decreased? Explain your thinking.</li>
                        <li>
                          Look at the data below. Make a claim about whether light bulb A or B is more energy efficient:
                          <ol type="a">
                            <li>To your eyes, light bulbs A and B appear to emit the same brightness of light.</li>
                            <li>Using a sensitive light meter, bulb B registers about one-third the light output as bulb A.</li>
                            <li>After being on for 10 minutes, bulb A is much hotter than bulb B.</li>
                          </ol>
                        </li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="carbon-calculator-and-your-first-job" className="cardHeader">
                      <h2>
                        C. <span className="headerFontBlue">Carbon Calculator and Your First Job</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image33.childImageSharp.fluid}
                            alt="Home energy consultant"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="verticalImageRight rounded mb-3 ml-3 img-fluid d-none d-md-block"
                        fluid={props.data.image33.childImageSharp.fluid}
                        alt="Home energy consultant"
                      />

                      <p>
                        It's your first job! You are now a <em>Home Energy Consultant!</em>
                      </p>

                      <p>
                        You worked hard in school and did pretty well in science. You volunteered in the outreach program with your local power company. The director saw that you worked well with the staff, as well as the customers. When a position opened up, the director thought of you and invited you to apply. You got the job!
                      </p>

                      <p>
                        Now you get to start on your first big project! The power company is launching a new initiative: helping customers use less energy. Customers will save money on their monthly bill, and the power plant will use less coal. This means less carbon dioxide to the atmosphere.
                      </p>

                      {/* <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image34.childImageSharp.fluid}
                            alt="Laptop on the table"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageLeft rounded mb-3 mr-3 mt-3 img-fluid d-none d-md-block"
                        fluid={props.data.image34.childImageSharp.fluid}
                        alt="Laptop on the table"
                      /> */}

                      <p>
                        You'll use a computer model called a carbon calculator to determine the carbon footprint for you or your family. Think of a footprint in snow: it leaves a mark of your presence. Your footprint indicates how big your foot is. A <strong>carbon footprint</strong> shows the total emissions of carbon as CO<sub>2</sub> related to all of the activities you do (like driving, eating, or using appliances). You'll see more examples of those activities in the model.
                      </p>

                      <p className="lead">Carbon Calculator (Nature Conservancy)</p>
                      <p>
                        Before you can use the model with customers, you need to test it. You can use your home and family to test decisions to use less energy, emit less carbon, and save money. Continue with the steps below to start using the model.
                      </p>

                      <ol start="5">
                        <li>Launch the Carbon Calculator</li>
                        <li>Start with the <strong>Get Started</strong> tab. Select the number of people in your home. Next, select <strong>Calculate for My Household</strong>. The results will include everyone in your household.</li>

                        <Row className="d-md-none">
                          <Col>
                            <Img
                              className="rounded img-fluid mb-3"
                              fluid={props.data.image35.childImageSharp.fluid}
                              alt="Carbon calculator screenshot"
                            />
                          </Col>
                        </Row>
                        <Img
                          className="verticalImageRight rounded mb-3 ml-3 mt-3 img-fluid d-none d-md-block"
                          fluid={props.data.image35.childImageSharp.fluid}
                          alt="Carbon calculator screenshot"
                        />

                        <li>
                          Click on the category <strong>Home Energy</strong>. You should see a screen like the one below.
                          <ol type="a">
                            <li>Select the type of house and number of bedrooms. A detached house means that it stands alone, and is not part of a joined group of homes, condos, or apartments.</li>
                            <li>Select your state. At the bottom, results show an initial reading of how you compare with other states.</li>
                            <li>
                              Adjust the efficiency of these categories in the model:
                              <ul>
                                <li>Home heating or cooling</li>
                                <li>Lighting</li>
                                <li>Appliances and electronics</li>
                                <li>Hot water</li>
                              </ul>
                            </li>
                            <li>Which has the largest potential impact on emitting less carbon and saving money?</li>
                          </ol>
                        </li>
                      </ol>

                      <p>
                        Your carbon connections extend beyond energy use in your home to other parts of your life. For a more complete picture of carbon and energy use, test the footprints of some other categories. The calculator will remember and add each category.
                      </p>

                      <ol start="8">
                        <li>
                          Click on <strong>Driving and Flying</strong> to explore those carbon connections for your family.
                          <ol type="a">
                            <li>Enter the type of car and miles driven. On average, families in the United States drive about 10,000 miles per year. If you have additional cars, click on <strong>Add vehicle</strong> to include them.</li>
                            <li>Maintaining your car affects its efficiency. Enter estimates for two key factors: air filter and tire pressure. How much do these two factors affect your carbon footprint? The column to the right will show how much, in terms of tons of CO2 per year.</li>
                            <li>Some people fly to a vacation or to see relatives. That can be a lot of fun! To test the carbon footprint of flying, enter the number of flights your family takes per year.</li>
                          </ol>
                        </li>
                        <li>
                          Investigate the next category, <strong>Food &amp; Diet</strong>. That's another key carbon connection.
                          <ol type="a">
                            <li>How often do you include meat in your diet? This question is here because feeding livestock and transporting meat uses energy and also uses land. The column shows you the equivalent amount in terms of CO2 emissions.</li>
                            <li>Do you eat organic foods? Enter your estimate, and test some categories. Organic foods lead to a lower carbon footprint because they don't use fertilizers and pesticides. Using fertilizers and pesticides require a lot of energy to produce, transport, and apply to crops.</li>
                          </ol>
                        </li>
                        <li>
                          Click on the next category, <strong>Recycling &amp; Waste</strong>. You can test the impact of this category on your total carbon footprint.
                          <ol type="a">
                            <li>Enter an estimate for how much you recycle.</li>
                            <li>Estimate how often you compost food or yard trimmings.</li>
                          </ol>
                        </li>
                        <li>
                          Click on the tab for <strong>Results</strong>. You can return to adjust values in other tabs. <em>Do not exit Results until you have completed the questions below, or you will need to enter your data again.</em>
                          <ol type="a">
                            <li>Write your estimated emissions and the United States average. Describe how they compare.</li>
                            <li>Compare your emissions with the world average. Why do you think that your emissions are lower or higher?</li>
                            <li>Look at the pie graph of energy use by category in the carbon calculator. In what two categories do you use the most energy?</li>
                          </ol>
                        </li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="reflect-and-connect" className="cardHeader">
                      <h2>
                        D. <span className="headerFontBlue">Reflect and Connect</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        Carbon calculators help you see the size of your carbon footprint. They also help you test and simulate what it would take to reduce your footprint. The questions below will help you reflect on different ways to reduce your carbon footprint. Remember that a smaller carbon footprint probably also means saving money on your monthly bill.
                      </p>

                      <ol start="12">
                        <li>
                        Could your family reduce your carbon footprint by five percent? Use the steps below to test this.
                          <ol type="a">
                            <li>Write the value of carbon emissions for your family from step 11a.</li>
                            <li>What value is five percent less than step 12a? (That is, what value is 95% of step 12a?)</li>
                            <li>
                              Review the categories on the tabs across the top of the calculator. Click on a category and record the size of changes that you think you could manage. Does the change give you a five percent reduction? <br />
                              (NOTE: To see carbon changes, watch the "Home Energy Use Total" bar on the lower-left of the window; the "Total Greenhouse Gas Emissions" bar doesn't record individual changes.)
                            </li>
                            <li>Record two different ways you could make that reduction.</li>
                          </ol>
                        </li>
                        <li>In your home, you can do many things that will help reduce your use. In fact, there are so many things that they could not be included in the carbon calculator. Review the list of Energy Saving Tips and identify three tips that would be relatively easy for you to do.</li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="reflect-and-connect" className="cardHeader">
                      <h2>
                        <span className="headerFontBlue">Energy Savings Tips</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p className="lead">Energy Savings Tips</p>
                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ TVWeather36 }
                            alt="Weather forecast on TV"
                          />
                        </Col>
                      </Row>
                      <img
                        className="verticalImageRight rounded mb-3 ml-3 img-fluid d-none d-md-block"
                        src={ TVWeather36 }
                        alt="Weather forecast on TV"
                      />

                      <p>
                        At home, your family may have a television or computer. While you are at school, you would not think that they are using electrical energy. But believe it or not, they do use small amounts of electrical energy&mdash;just by being plugged-in! This loss of electrical energy is like a water faucet with a slow leak. Over time, even small drips of water really add up to lots of water wasted. This is good to know if you want to reduce your carbon footprint and save money.
                      </p>

                      <p>
                        You and your family can take some simple steps to save energy. For example, unplugging a computer or television might not be convenient. Rather, it may be easier to plug them into a power strip with an on/off switch. Turning the switch "off" when they aren't in use stops that electricity leak. But don't do that for your refrigerator!
                      </p>

                      <p>
                        The sections below have some other ideas to help you and your family reduce the energy you use. You will save money too. Think about what applies to your family. For example, about half the energy used in your home may come from heating and cooling. Are your winters cold and you need heating? Do you have hot summers and need cooling? Or maybe you need both. In other cases, steps you can take may be sort of obvious&mdash;like turning-off lights that aren't being used! A lot of people making small changes can really add up to a lot less carbon moving into the atmosphere.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ homeEnergyCategories37 }
                            alt="Home energy use categories"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter mb-4 rounded img-fluid d-none d-md-block"
                        src={ homeEnergyCategories37 }
                        alt="Home energy use categories"
                      />

                      <p className="lead">1. Heating</p>
                      <p>
                        Home heating in cold months may be an important use of energy for your family. It's the main energy-use category, so conserving a small amount here goes a long way to reducing the energy you use. From the list below, see what may apply to your home.
                      </p>

                      <ul>
                        <li><strong>Change your air filter:</strong> Checking air filters each month can save energy. Replace filters when they are discolored. A heating system has to work hard and use more energy to pump air through a clogged filter.</li>
                        <li><strong>Use a programmable thermostat:</strong> This helps you to lower temperatures at set periods on a regular basis. For example, when you leave for school, you might forget to lower the temperature during the day. In contrast, by programming your home to 55&deg;F on winter days when nobody is home gives savings that really add up.</li>
                        <li><strong>Get a tune-up:</strong> As with a car, a tune-up of your heating system can improve efficiency and save energy.</li>
                        <li><strong>Windows:</strong> Replacing windows with ENERGY STAR<sup>&reg;</sup>-qualified windows could save &#36;20-&#36;100 per year in energy use.</li>
                      </ul>

                      <p className="lead">2. Cooling</p>
                      <p>
                        Especially if you live in a warm climate, home cooling can be an important use of energy. It's a main category of energy use, so conserving a small amount here goes a long way to reducing energy use. From the list below, see what may apply to your home.
                      </p>

                      <ul>
                        <li><strong>Room air conditioner:</strong> If you are buying a new air conditioner, consider purchasing an ENERGY STAR<sup>&reg;</sup> model. They use up to 10 percent less than standard models. They might cost more in the store, but you'll bank dollars on your energy bill.</li>
                        <li><strong>Insulate air conditioner:</strong> In the winter, use a tight-fitting air conditioning cover. Also make sure that the fit is tight for your air conditioner.</li>
                        <li><strong>Windows:</strong> Replacing windows with ENERGY STAR<sup>&reg;</sup>-qualified windows could save &#36;20-&#36;100 per year in energy use.</li>
                      </ul>

                      <p className="lead">3. Water Heating</p>
                      <p>
                        Hot water is important in your home. You use it in the washer, to clean dishes, and to bathe. The average household spends &#36;400-600 a year on heating water. If you have the opportunity to adjust the water-heating system that you are using, you can cut the cost in half. Here are some energy-saving tips:
                      </p>

                      <ul>
                        <li><strong>Reduce water heater temperature:</strong> Do you really need your hot water at 140-150&deg;F? This could scald your skin, so you need to add cold water anyway. At 120&deg;F, you'll use much less energy, save money, and still have the hot water you need.</li>
                        <li><strong>Insulate hot water pipes:</strong> Doing this leaves water hotter when you use it; less heat is lost between your water heater and your faucet. If your hot water tank isn't insulated, an insulating jacket will help too.</li>
                        <li><strong>Vacation:</strong> If you leave for a vacation, don't forget to turn the water heater temperature way down. Look to see if it has a "vacation" setting. Then you're not keeping water hot the whole time you're gone.</li>
                        <li><strong>New water heater:</strong> Water heaters last 10-20 years. If you need to buy a new one, check that it has earned the ENERGY STAR<sup>&reg;</sup> rating. It may cost a bit more at the store, but it saves energy and saves you money over just a few years.</li>
                        <li><strong>Reduce shower times:</strong> Shorter showers mean less hot water. Low-flow shower heads also use less hot water.</li>
                      </ul>

                      <p className="lead">4. Appliances</p>
                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ appliances38 }
                            alt="Appliances"
                          />
                        </Col>
                      </Row>
                      <img
                        className="smallImageRight mb-3 ml-3 img-fluid d-none d-md-block"
                        src={ appliances38 }
                        alt="Appliances"
                      />

                      <p>
                        On average, your family may spend about &#36;2,200 a year on energy for appliances. If you have the chance to buy a new appliance, compare the price for one with an ENERGY STAR<sup>&reg;</sup> rating. They may cost a little more in the store, but they will save money in the long-term. Your energy bills will be less, and you will reduce carbon. Remember that estimated factor of 2.2 to generate electric energy. Some other ideas include:
                      </p>

                      <ul>
                        <li><strong>Clothes washer:</strong> When possible, wash clothes with cold or warm water rather than hot water. Heating water can account for 90 percent of the energy use in washing clothes.</li>
                        <li><strong>Clothes dryer:</strong> Don't over-dry your clothes. You can also increase dryer efficiency by cleaning the lint trap with each load.</li>
                        <li><strong>Dishwasher:</strong> Make sure the dishwasher is full before turning it on. You'll use the same amount of energy and water whether it is full or not. You may be able to select the no-heat drying option, and let clean dishes air dry. This can save up to 20 percent of total energy use.</li>
                        <li><strong>Microwave:</strong> Use a microwave rather than oven or range to reheat small portions of food.</li>
                        <li><strong>Range:</strong> Don't use a small pot on a large burner. If you do this, nearly half of the heat from the burner will go around the pot. Using a cover on the pot also helps you cook more efficiently.</li>
                        <li><strong>Refrigerator:</strong> Keep it at a setting of 35-38&deg;F, and check that seals around the door are airtight. Reduce the time that the door is open.</li>
                      </ul>

                      <p className="lead">5. Lighting</p>
                      <p>
                        You don't need to replace every bulb in your house with an ENERGY STAR<sup>&reg;</sup>-rated bulb. You can start, for example, with your five most-used lights and replace those. This could save up to &#36;70 per year.
                      </p>

                      <ul>
                        <li><strong>CFL bulbs:</strong> Compact fluorescent bulbs (CFL) emit bright light, use 75 percent less energy, and last up to 10 times longer than incandescent bulbs. Start by trying them a few at a time.</li>
                        <li><strong>Porch light:</strong> An outdoor porch light can be one of the most-used fixtures at home.</li>
                        <li><strong>Indoor lighting:</strong> Turn lights off when not in use.</li>
                      </ul>

                      <p className="lead">6. Electronics</p>
                      <p>
                        On average, the electronics in your house account for about 15 percent of your electric energy. This comes from using televisions, DVD players or recorders, cell phones, computers, cameras, stereos, kitchen appliances, or game players. Many of these have power adapters or chargers. You can do a few things to reduce use from these.
                      </p>

                      <ul>
                        <li><strong>"Phantom" loads:</strong> This is the energy used while your electronics are "off." For the average home, estimates are that 75 percent of the electric energy used by electronics and appliances are through phantom loads. You can reduce this use by unplugging the electronics, or bundling them on a power strip with on/off switch.</li>
                        <li><strong>Computers:</strong> Screen savers don't really reduce use by monitors. When your computer isn't in use, switch off the monitor and put the computer in sleep mode. If you'll be away from your computer for more than two hours, shut it down.</li>
                        <li><strong>Computer power cord:</strong> With a laptop, place the power cord in a power strip or unplug it; otherwise, it will draw electric energy continuously.</li>
                        <li><strong>Televisions, DVD players:</strong> Bundle these in power strips with on/off buttons where possible.</li>
                        <li><strong>Chargers:</strong> When recharging batteries for a cell phone, computer, or camera, unplug the unit when batteries are recharged, or it will continue to draw power. You can tell this because they'll stay warm to the touch.</li>
                        <li><strong>Game consoles:</strong> When not using your game console, unplug it, or switch it off with a power strip. You can reduce energy use by about 90 percent.</li>
                        <li><strong>Standby mode:</strong> In the future, more and more products will have standby modes that use less electric energy. Check that you know where standby mode is.</li>
                      </ul>

                      <p className="lead">7. Other</p>
                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ biking39 }
                            alt="Biking"
                          />
                        </Col>
                      </Row>
                      <img
                        className="smallImageRight rounded mb-3 ml-3 img-fluid d-none d-md-block"
                        src={ biking39 }
                        alt="Biking"
                      />

                      <ul>
                        <li><strong>Recycle, reuse:</strong> Making products from recycled material uses much less energy than making products from raw earth materials.</li>
                        <li><strong>Transportation:</strong> Walk, bike, or use public transportation when possible.</li>
                      </ul>

                      <p>
                        As you have seen in <em>Carbon Connections</em>, the carbon cycle and climate have a lot of connections. And you and your family are connected in many different ways. But what do your friends think about the carbon and climate connections? Are they learning something too from <em>Carbon Connections</em> or their science class? You'll get to see what they think in a video and some of their posts from social pages in the next lesson.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <ButtonNext
                ButtonText="3.5 Carbon Claims"
                NextLink="/unit-3/3.5-carbon-claims/"
              />

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ItStartsAtHome34

export const query = graphql`
  query {
    image29: file(relativePath: { eq: "student-pages/unit-3/29_classroom_kids.jpg" }) {
      ...rowImage
    }
    image30: file(relativePath: { eq: "student-pages/unit-3/30_lightbulb.jpg" }) {
      ...rowImage
    }
    image31: file(relativePath: { eq: "student-pages/unit-3/31_refrigerator.jpg" }) {
      ...rowImage
    }
    image32: file(relativePath: { eq: "student-pages/unit-3/32_power_grids.jpg" }) {
      ...rowImage
    }
    image33: file(relativePath: { eq: "student-pages/unit-3/33_energy_consultant.jpg" }) {
      ...rowImage
    }
    image34: file(relativePath: { eq: "student-pages/unit-3/34_laptop_on_table.jpg" }) {
      ...rowImage
    }
    image35: file(relativePath: { eq: "student-pages/unit-3/35_home_energy.jpg" }) {
      ...rowImage
    }
  }
`
